import { Box, CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

import BottomMarker from '~/components/BottomMarker';

type Props = {
  isLoading: boolean;
  loadNextPage(): void;
};

export const ProductListInfiniteScrollTrigger: React.FC<Props> = ({
  isLoading,
  loadNextPage,
}) => {
  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        </Grid>
      )}

      {!isLoading && <BottomMarker onReached={loadNextPage} />}
    </>
  );
};
