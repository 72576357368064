import {
  generateLocalizationHeaders,
  Locale,
  REQUEST_ID_HEADER_NAME,
  traceabilityHeadersBrowser,
} from '@finn/ui-utils';
import * as Sentry from '@sentry/node';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import omit from 'lodash/omit';
import { stringify } from 'query-string';

import config from '~/config';
import { AvailableFilters, Filters, VehicleView } from '~/types/filter';
import { VehicleOfferType } from '~/types/vehicle';
import { stringifyFilter } from '~/utils/filter';
import { getFinnRequestId } from '~/utils/general';

import { PRICING_TYPE, VehiclesResponse } from '../contexts/Vehicle';

export const instance = axios.create({
  baseURL: config.PRODUCT_API_URL,
  transformRequest: (data, headers) => {
    const requestId = getFinnRequestId();
    Sentry.configureScope((scope) => {
      scope.setTag(REQUEST_ID_HEADER_NAME, requestId);
    });
    headers[REQUEST_ID_HEADER_NAME] = requestId;

    return data;
  },
});

axiosRetry(instance, { retries: 3, shouldResetTimeout: true });

export type GetVehiclesParams = {
  filter?: Filters;
  offset?: number;
  limit?: number;
  view?: VehicleView;
  offerType?: VehicleOfferType;
  locale: string;
};

type GetVehiclesRequestUrl = (params: {
  filter?: Filters;
  offset?: number;
  limit?: number;
  view?: string;
  offerType?: VehicleOfferType;
}) => string;

// we need primitive type for swr
export const getVehiclesRequestUrl: GetVehiclesRequestUrl = ({
  filter,
  offset = 0,
  limit = 9,
  view,
}) => {
  const params = filter ? stringifyFilter(filter) : '';
  const extraParams = stringify(
    {
      limit,
      offset,
      view,
    },
    {
      arrayFormat: 'none',
      skipNull: true,
    }
  );

  return `/cars?${extraParams}&${params}`;
};

const fleetApiHeaders = (locale: Locale): Record<string, string | Locale> => {
  return {
    ...generateLocalizationHeaders(locale),
    ...traceabilityHeadersBrowser(),
  };
};

export const getVehiclesFromRequestUrl = async (url, locale) => {
  const response = await instance.get(url, {
    headers: fleetApiHeaders(locale),
  });

  const plpRelevantData = {
    offset: response.data?.offset,
    total_results: response.data?.total_results,
    results: (response.data?.results || []).map((vehicle) => {
      return omit(vehicle, [
        'brand.thumbnails',
        'config',
        'description',
        'efficiency_image',
        'equipment',
        'pictures',
      ]);
    }),
  };

  return plpRelevantData;
};

const setDefaultFilters = (initialFilters: Filters | undefined) => {
  let filters = { ...initialFilters };
  if (!filters) {
    filters = {};
  }
  if (!filters.pricing_type) {
    filters.pricing_type = PRICING_TYPE.DOWNPAYMENT;
  }

  return filters;
};

export async function getVehicles({
  filter: initialFilters,
  offset = 0,
  limit = 9,
  view,
  locale,
}: GetVehiclesParams): Promise<VehiclesResponse> {
  const filter = setDefaultFilters(initialFilters);

  const vehiclesRequestUrl = getVehiclesRequestUrl({
    filter,
    offset,
    limit,
    view,
  });

  return await getVehiclesFromRequestUrl(vehiclesRequestUrl, locale);
}

// we need primitive type for swr
export const getFilterRequestUrl = (filterValues: Filters | undefined) => {
  const params = filterValues ? stringifyFilter(filterValues) : '';

  return `/filters/cars?${params}`;
};

export const getFilterFromRequestUrl = async (url, locale) => {
  const response = await instance.get(url, {
    headers: fleetApiHeaders(locale),
  });

  return response.data;
};

export async function getFilter(
  initialFilters: Filters | undefined,
  locale: string
): Promise<AvailableFilters> {
  const filterValues = setDefaultFilters(initialFilters);

  const requestUrl = getFilterRequestUrl(filterValues);

  return await getFilterFromRequestUrl(requestUrl, locale);
}

export async function getFilterForDisplayedCars(
  filterValues: Filters | undefined,
  locale: string
) {
  const filters = {
    ...(filterValues ?? {}),
    view: VehicleView.DISPLAYED_CARS,
  };

  return getFilter(filters, locale);
}
