import {
  Brand,
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
  parseFilterValues,
} from '@finn/ua-vehicle';
import { useMemo } from 'react';
import { IntlShape } from 'react-intl';

import {
  useAvailableFilters,
  useFilterValues,
  useImplicitFilters,
} from '~/modules/products/filters';
import { getTermsFilterData } from '~/utils/plp/plp';
import { useIsPlpPage } from '~/utils/plp/useIsPlpPage';

export const defaultFilterValues = {
  [FilterKey.BRANDS]: [],
  [FilterKey.MODELS]: [],
  [FilterKey.FUELS]: [],
  [FilterKey.CAR_TYPES]: [],
  [FilterKey.MIN_PRICE]: undefined,
  [FilterKey.MAX_PRICE]: undefined,
  [FilterKey.MIN_PRICE_MSRP]: undefined,
  [FilterKey.MAX_PRICE_MSRP]: undefined,
  [FilterKey.TERMS]: [],
  [FilterKey.AVAILABLE_TO]: null,
  [FilterKey.AVAILABLE_FROM]: null,
  [FilterKey.HAS_DEALS]: null,
  [FilterKey.GEARSHIFTS]: [],
  [FilterKey.HAS_HITCH]: null,
  [FilterKey.IS_FOR_BUSINESS]: null,
  [FilterKey.IS_YOUNG_DRIVER]: null,
  [FilterKey.SORT]: undefined,
};

export type Range = [number | undefined, number | undefined];

export type FiltersState = Pick<
  FilterValuesObject,
  | typeof FilterKey.BRANDS
  | typeof FilterKey.MODELS
  | typeof FilterKey.MIN_PRICE
  | typeof FilterKey.MAX_PRICE
  | typeof FilterKey.MIN_PRICE_MSRP
  | typeof FilterKey.MAX_PRICE_MSRP
  | typeof FilterKey.AVAILABLE_FROM
  | typeof FilterKey.AVAILABLE_TO
  | typeof FilterKey.TERMS
  | typeof FilterKey.CAR_TYPES
  | typeof FilterKey.GEARSHIFTS
  | typeof FilterKey.FUELS
  | typeof FilterKey.HAS_HITCH
  | typeof FilterKey.IS_FOR_BUSINESS
  | typeof FilterKey.IS_YOUNG_DRIVER
  | typeof FilterKey.SORT
  | typeof FilterKey.HAS_DEALS
  | typeof FilterKey.PRODUCT_GROUP
  | typeof FilterKey.HAS_RETENTION_DEALS
>;

export type FilterOptionClickFunction = (filtersObject: FiltersState) => void;

export type FilterToggleData = {
  value: string | number;
  label: string;
  image?: any;
  selected?: boolean;
  disabled?: boolean;
  shouldReturnArray?: boolean;
  hidden?: boolean;
};

export type FilterData = {
  filterKey: FilterKey;
  brands?: string[];
  selectedValues?: string[] | number[];
  availableFilterValues: FilterToggleData[] | FilterToggleData[][];
};

export type FilterDataArray = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

type SelectProps = {
  value: string | number | boolean;
  selected?: boolean;
  onClick: (filterObject: FiltersState) => void;
  filterKey: FilterKey;
  selectedValues?: unknown[];
  shouldReturnArray?: boolean;
};

export const handleSingleSelect = ({
  value,
  selected,
  onClick,
  filterKey,
  shouldReturnArray = true,
}: SelectProps) => {
  let updatedValue: string | number | boolean | (string | number | boolean)[];
  if (shouldReturnArray) {
    updatedValue = selected ? [] : [value];
  } else {
    updatedValue = selected ? undefined : value;
  }
  onClick({ [filterKey]: updatedValue });
};

export const handleMultiSelect = ({
  value,
  selected,
  onClick,
  filterKey,
  selectedValues = [],
}: SelectProps) => {
  const updatedSelectedValues = selected
    ? selectedValues?.filter((item) => item !== value)
    : [...selectedValues, value];
  onClick({ [filterKey]: [...updatedSelectedValues] });
};

export const getSelectedCount = (selectedValues: unknown[]) => {
  const length = selectedValues.filter((value) => value).length;
  const selectedCount = length > 0 && length;

  return selectedCount;
};

export const parseFiltersResponse = (
  filterKey: FilterKey,
  filtersResponse: FiltersResponse,
  i18n: IntlShape
) => {
  if (filterKey === FilterKey.TERMS) {
    return getTermsFilterData(filtersResponse[filterKey], i18n, 'Flex');
  } else {
    return filtersResponse[filterKey];
  }
};

export const useOriginalProductGridFilters = () => {
  const implicitFilters = useImplicitFilters();

  return useAvailableFilters({
    filters: parseFilterValues(implicitFilters),
  });
};

export const removeProductGroupFilter = (
  filters: FilterValuesObject
): FilterValuesObject => {
  return Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== FilterKey.PRODUCT_GROUP)
  );
};

export const useIsProductGridFiltered = () => {
  const filterValues = useFilterValues();

  const isProductGrid = !useIsPlpPage();

  return isProductGrid && Object.keys(filterValues).length > 0;
};

export const useProductGridBrands = ({ getAllBrandData }) => {
  const implicitFilters = useImplicitFilters();

  const originalProductGridFilters = useAvailableFilters({
    filters: parseFilterValues(implicitFilters),
  });

  return originalProductGridFilters.brands
    .filter((brand) => brand.available)
    .map((brand) => (getAllBrandData ? brand : brand.id));
};

export const useProductGridModels = (): string[] => {
  const productGridBrandsData = useProductGridBrands({ getAllBrandData: true });

  const productGridModels = useMemo(() => {
    const models = [];
    (productGridBrandsData as Brand[]).forEach((productGridBrand) => {
      productGridBrand.models.forEach((model) => {
        if (model.available) {
          models.push(model.id);
        }
      });
    });

    return models;
  }, [productGridBrandsData]);

  return productGridModels;
};
